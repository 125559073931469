import React from 'react'
import { CategoriesLayout } from '../../layouts/CategoriesLayout/CategoriesLayout'
import { ActivityCascad } from '../../cascad-screens/ActivityCascad'
import { getImage } from '../../utils/getImage'
import classes from './NewsPage.module.css'

export const NewsPage = ({ data }) => (
  <CategoriesLayout isNews heading={data?.theme}>
    {data ? (
      <div className={classes.container}>
        <p className={classes.description}>{data.description}</p>
        <img className={classes.image} src={getImage(data?.images[0])} />
        <div className={classes.text_wrapper}>
          {data?.text && (
            <div
              className={classes.text}
              dangerouslySetInnerHTML={{
                __html: data.text,
              }}
            ></div>
          )}
        </div>
      </div>
    ) : (
      <ActivityCascad />
    )}
  </CategoriesLayout>
)
